import { useEffect, useMemo, useState } from "react";

import {
  EmptyResource,
  ResourceDefinitions,
  ResourceType,
} from "../../types/serializers";

import useStore from "./useStore";

const useResources = <
  P extends ResourceType,
  T extends ResourceDefinitions[P] = ResourceDefinitions[P]
>(
  resources: EmptyResource<P>[] | undefined,
  opts?: { root?: string }
): Array<T | undefined> => {
  const [invalidations, setInvalidate] = useState(0);
  const store = useStore();
  useEffect(() => {
    if (resources) {
      const subscriptions = resources.map(({ type, id }) =>
        store.subscribe(type, id, setInvalidate, opts?.root)
      );

      return () => subscriptions.forEach((subscription) => subscription());
    }

    return () => null;
  }, [resources]);

  return useMemo(() => {
    if (!resources) {
      return [];
    }

    return resources.map(({ type, id }) =>
      store.getResource(type, id, opts?.root)
    ) as T[];
  }, [resources, invalidations]);
};

export default useResources;
