import { listAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(() => ({
  container: {
    listStylePos: "inside",
  },
}));

const listTheme = defineMultiStyleConfig({ baseStyle });

export default listTheme;
