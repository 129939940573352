import { Provider } from "@rollbar/react";
import React, { PropsWithChildren } from "react";

import ErrorBoundary from "./ErrorBoundary";

const ErrorHandler = ({
  children,
  token,
}: PropsWithChildren & { token?: string }) => {
  return (
    <Provider
      config={{
        accessToken: token,
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
};

export default ErrorHandler;
