import debounce from "lodash/debounce";
import {
  GroupBase,
  OptionsOrGroups,
} from "react-select/dist/declarations/src/types";

import { EmptyResource, ResourceType } from "../../types/serializers";
import { trackEvent } from "../lib/analytics";
import { CollectionOpts, getCollectionId } from "../lib/ids";

import useStore from "./useStore";

const DEBOUNCE = 500;

export type AsyncCallback<T = EmptyResource> = (
  options: OptionsOrGroups<T, GroupBase<T>>
) => void;

type LoadOptions<T = EmptyResource> = (
  inputValue: string,
  callback: AsyncCallback
) => Promise<OptionsOrGroups<T, GroupBase<T>>> | void;

const useInstantSearch = <T = EmptyResource>(
  resourceType: ResourceType,
  collectionOpts: CollectionOpts = {}
): LoadOptions<T> => {
  const store = useStore();

  const loadOptions = (inputValue: string, callback: AsyncCallback) => {
    trackEvent("instant_search", { query: inputValue });

    const collectionId = getCollectionId(resourceType, {
      ...collectionOpts,
      query: inputValue,
    });
    store
      .getOrFetch(ResourceType.Collection, collectionId)
      .then((collection) => {
        callback(
          collection?.relationships.items.data?.map((option) => ({
            ...option,
            value: option.id,
          })) ?? []
        );
      });
  };

  return debounce(loadOptions, DEBOUNCE);
};

export default useInstantSearch;
