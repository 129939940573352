import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const archive = definePartsStyle({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
  },
  td: {
    "&:last-child": {
      borderRight: "none",
    },
    ":first-of-type": {
      paddingLeft: 0,
    },
    borderRight: "1px solid",
    borderRightColor: "background.stroke",
    verticalAlign: "top",
  },
  th: {
    ":first-of-type": {
      paddingLeft: 0,
    },
    fontFamily: "body",
    fontSize: "1em",
    fontWeight: "bold",
    letterSpacing: "normal",
    textTransform: "unset",
  },
  tr: {
    "&::after": {
      backgroundColor: "background.stroke",
      bottom: "-5px",
      content: "''",
      height: "1px",
      left: 0,
      position: "absolute",
      right: 0,
    },
    position: "relative",
  },
});

const blog = definePartsStyle({
  table: { borderBottom: "1px solid #e7eaec" },
  td: {
    paddingY: 2,
  },
  th: {
    fontFamily: "body",
    fontSize: "1em",
    letterSpacing: "normal",
    lineHeight: 5,
    paddingY: 2,
    textTransform: "unset",
  },
  thead: {
    "& tr": {
      background: "#f9e5b978",
    },
  },
  tr: {
    "&:nth-of-type(even)": { background: "#ffde9933" },
    borderTop: "1px solid #80808026",
  },
});

const Table = defineMultiStyleConfig({ variants: { archive, blog } });

export default Table;
