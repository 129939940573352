import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

import breakpoints from "../theme/breakpoints";
import colors from "../theme/colors";
import components from "../theme/components";
import fonts from "../theme/fonts";
import styles from "../theme/styles";
import textStyles from "../theme/textStyles";
import zIndices from "../theme/zIndices";

import Toast from "./toast";

const theme = extendTheme({
  breakpoints,
  colors,
  components,
  fonts,
  styles,
  textStyles,
  zIndices,
});

interface StyleProviderProps extends PropsWithChildren {
  resetScope?: string;
}

const StyleProvider = ({ children, resetScope }: StyleProviderProps) => (
  <ChakraProvider
    resetScope={resetScope}
    theme={theme}
    toastOptions={{
      defaultOptions: {
        position: "top",
        render: Toast,
        status: "success",
      },
    }}
  >
    {children}
  </ChakraProvider>
);

export default StyleProvider;
