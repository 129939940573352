import { Box, BoxProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const Container = ({
  children,
  ...otherProps
}: PropsWithChildren & BoxProps) => (
  <Box
    margin="auto"
    maxWidth="1370px"
    paddingX={{
      base: "16px",
      sm: "20px",
      md: "30px",
      lg: "40px",
      xl: 0,
    }}
    {...otherProps}
  >
    {children}
  </Box>
);

export default Container;
