import { ResourceType } from "../../../types/serializers";

export interface ModelConfig {
  fetch: boolean;
}

class Config {
  private models: Partial<Record<ResourceType, ModelConfig>>;

  public constructor(models?: Partial<Record<ResourceType, ModelConfig>>) {
    this.models = models ?? {};
  }

  public modelIsFetchable(resourceType: ResourceType) {
    return this.models[resourceType]?.fetch ?? true;
  }
}

export default Config;
