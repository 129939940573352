import Accordion from "./components/Accordion";
import Button from "./components/Button";
import Heading from "./components/Heading";
import Input from "./components/Input";
import Link from "./components/Link";
import List from "./components/List";
import NumberInput from "./components/NumberInput";
import Select from "./components/Select";
import Table from "./components/Table";
import Tabs from "./components/Tabs";

const components = {
  Accordion,
  Button,
  Heading,
  Input,
  Link,
  List,
  NumberInput,
  Select,
  Table,
  Tabs,
};

export default components;
