import {
  DefinitionForType,
  EmptyResource,
  ResourceType,
} from "../../types/serializers";

import useResourceSubscription from "./useResourceSubscription";
import useStore from "./useStore";

const useResource = <
  P extends ResourceType,
  T extends DefinitionForType<P> = DefinitionForType<P>
>(
  resource: Partial<EmptyResource<P>> | undefined,
  opts?: { root?: string }
): T | undefined => {
  const { type, id } = resource ?? {};
  const store = useStore();
  useResourceSubscription(resource, opts);

  if (!type || !id) {
    return undefined;
  }

  return store.getResource(type, id, opts?.root) as T;
};

export default useResource;
