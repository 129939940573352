import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const basic = definePartsStyle({
  button: {
    _hover: {
      bg: "transparent",
      fontWeight: "bold",
    },
    color: "primary.darkActive",
    paddingLeft: "0",
  },
  icon: {},
  panel: {
    color: "black",
    paddingLeft: "0",
  },
});

const home = definePartsStyle({
  button: {
    backgroundColor: "background.accordion",
    borderRadius: "8px",
    color: "primary.darkActive",
    fontWeight: "bold",
    marginBottom: "8px",
  },
  container: {
    border: "none",
  },

  icon: {},
  panel: {
    color: "black",
  },
});

const accordionTheme = defineMultiStyleConfig({
  variants: { basic, home },
});

export default accordionTheme;
