import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const outline = definePartsStyle({
  field: { bg: "white" },
});

const NumberInput = defineMultiStyleConfig({ variants: { outline } });

export default NumberInput;
