import { ElementType as ElType } from "domelementtype";
import { Document, Element, Text } from "domhandler";
import { Node as NodeType } from "domhandler/lib/node";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export const isNumber = (value: unknown): value is number =>
  typeof value === "number";

export const isFunction = (value: unknown): value is (args: any) => any =>
  typeof value === "function";

export const isElementNode = (el?: NodeType): el is Element =>
  el?.type === ElType.Tag;

export const isDocumentNode = (el?: NodeType): el is Document =>
  el?.type === ElType.Root;

export const isTextNode = (el?: NodeType): el is Text =>
  el?.type === ElType.Text;
