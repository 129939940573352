import { GridItem, Heading, chakra } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

interface MainHeaderProps extends PropsWithChildren {
  marginBottom?: string;
  pageTitle?: string | null;
  pullRight?: JSX.Element;
  subTitle?: string | null;
  title?: string | null;
}

const MainHeader = ({
  children,
  marginBottom,
  pageTitle,
  pullRight,
  subTitle,
  title,
}: MainHeaderProps) => (
  <GridItem area="header" as="header" flexShrink={0} overflow="auto">
    <Helmet>
      <title>{`${pageTitle ?? title} - Kandoor`}</title>
    </Helmet>
    {pullRight && <chakra.div float="right">{pullRight}</chakra.div>}
    {title && (
      <Heading as="h1" marginBottom={marginBottom} variant="xl">
        {title}
      </Heading>
    )}
    {subTitle && (
      <Heading as="h2" color="gray.subheader" variant="md">
        {subTitle}
      </Heading>
    )}
    {children}
  </GridItem>
);

export default MainHeader;
