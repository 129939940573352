import { LinkProps as ChakraLinkProps } from "@chakra-ui/layout/dist/link";
import {
  Link as ChakraLink,
  ResponsiveValue,
  forwardRef,
} from "@chakra-ui/react";
import React, { MouseEventHandler, ReactNode } from "react";
import { NavLink as RouteLink, useInRouterContext } from "react-router-dom";

import { ResourceType } from "../../../types/serializers";
import { isFunction } from "../../lib/typing";
import { routeKey, sectionPrefix } from "../store/Router";

import LinkOverlay from "./LinkOverlay";

interface LinkDataProps {
  "data-link-style"?: string;
  "data-slate-node"?: string;
  "data-testid"?: string;
}

interface LinkProps extends LinkDataProps {
  children?: ReactNode | (({ isActive }: { isActive: boolean }) => ReactNode);
  className?: string;
  color?: string;
  colorScheme?: string;
  display?: ResponsiveValue<string>;
  href?: string;
  onClick?: MouseEventHandler;
  overlay?: boolean;
  property?: string;
  target?: string;
  textDecoration?: string;
  title?: string;
  typeof?: string;
  variant?: string;
}

const reactRoutes = Object.values(ResourceType).map((value) =>
  routeKey(value as ResourceType)
);
export const isReactRoute = (href: string | undefined) =>
  reactRoutes.find(
    (route) =>
      href?.startsWith(GLOBALS.root + "/admin/" + route) ||
      href?.startsWith("/admin/" + route) ||
      href?.startsWith(GLOBALS.root + "/" + route) ||
      href?.startsWith("/" + route)
  );

export const isExternal = (href: string | undefined) => {
  const pathBase = sectionPrefix[GLOBALS.section] || "/";

  return !(
    href?.startsWith(GLOBALS.root + pathBase) || href?.startsWith(pathBase)
  );
};

const normalizeHref = (href?: string) =>
  href?.endsWith("/") && href !== "/" ? href.slice(0, -1) : href;

const As = forwardRef<ChakraLinkProps & LinkDataProps, "a">((props, ref) => {
  const {
    children,
    className,
    href,
    target,
    download,
    onClick,
    rel,
    property,
    title,
    typeof: typeOf,
    ...otherProps
  } = props;

  return (
    <RouteLink
      className={className}
      data-link-style={otherProps["data-link-style"]}
      data-slate-node={otherProps["data-slate-node"]}
      data-testid={otherProps["data-testid"]}
      download={download}
      property={property}
      ref={ref}
      rel={rel}
      target={target}
      title={title}
      to={href!.replace(GLOBALS.root, "")}
      typeof={typeOf}
      onClick={onClick}
    >
      {children}
    </RouteLink>
  );
});

const Link = forwardRef<LinkProps, "a">((props, ref) => {
  const {
    children,
    className,
    color,
    colorScheme,
    display,
    href,
    onClick,
    overlay,
    property,
    target,
    textDecoration,
    title,
    typeof: typeOf,
    variant,
    ...otherProps
  } = props;
  const external = isExternal(href);
  const legacyComponent = !useInRouterContext();
  const reactRouter = !external && !legacyComponent;
  const Component = overlay ? LinkOverlay : ChakraLink;

  const componentProps: ChakraLinkProps = {
    as: reactRouter ? As : undefined,
    className,
    color,
    textDecoration,
    variant,
  };
  if (colorScheme) {
    componentProps.colorScheme = colorScheme;
  }

  return (
    <Component
      {...componentProps}
      data-link-style={otherProps["data-link-style"]}
      data-slate-node={otherProps["data-slate-node"]}
      data-testid={otherProps["data-testid"]}
      display={display}
      href={normalizeHref(href)}
      property={property}
      ref={ref}
      rel={external ? "noopener noreferrer" : undefined}
      target={external ? "_blank" : target}
      title={title}
      typeof={typeOf}
      onClick={onClick}
    >
      {!reactRouter && isFunction(children) ? children({}) : children}
    </Component>
  );
});

export default Link;
