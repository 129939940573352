import { useToast } from "@chakra-ui/react";
import { useRollbar } from "@rollbar/react";
import HttpStatus from "http-status-codes";
import { t } from "i18next";
import { useState } from "react";

import { ResourceType } from "../../types/serializers";
import { JsonAPIData } from "../components/store/StoreContextProvider";
import { headers, setCsrfToken } from "../lib/api";

import {
  CallbackErrorHandler,
  CallbackSuccessHandler,
} from "./useResourceCallback";

const INVALID_AUTHENTICITY_TOKEN = "InvalidAuthenticityToken";

export type METHOD = "GET" | "POST" | "PUT" | "DELETE";

export interface FetchResult<T extends ResourceType | unknown = unknown> {
  json: JsonAPIData<T extends ResourceType ? T : unknown>;
  response: Response;
}

type FetchCallback = (
  data?: Record<string, unknown>,
  onSuccess?: CallbackSuccessHandler,
  onError?: CallbackErrorHandler
) => void;

type CallbackErrorHandlerWithFallback<
  T extends ResourceType | unknown = unknown
> = (
  body: FetchResult<T>,
  retry?: () => Promise<void | FetchResult<T>>
) => void;

export type UseFetchCallback = [callback: FetchCallback, pending: boolean];

const ERROR_MESSAGES = {
  [HttpStatus.FORBIDDEN]: t("shared:status.callback.forbidden"),
  [HttpStatus.NOT_FOUND]: t("shared:status.callback.notFound"),
  [HttpStatus.UNAUTHORIZED]: t("shared:status.callback.unauthorized"),
};

const fetchRequest = (
  url: string,
  body: Record<string, unknown> | undefined,
  method: METHOD,
  handleSuccess: CallbackSuccessHandler | undefined,
  handleError: CallbackErrorHandlerWithFallback,
  retrying?: boolean
) => {
  const retry = retrying
    ? undefined
    : () => fetchRequest(url, body, method, handleSuccess, handleError, true);

  return fetch(url, {
    body: JSON.stringify(body),
    headers: headers(),
    method,
    redirect: "manual",
    referrerPolicy: "no-referrer-when-downgrade",
  })
    .then((response) => {
      return response
        .json()
        .catch(() => ({}))
        .then((json) => {
          if (response.ok) {
            if (handleSuccess) {
              handleSuccess({ json, response });
            }
          } else {
            handleError({ json, response }, retry);
          }
        });
    })
    .catch(() => {
      return new Promise<FetchResult>(() => {
        const response = new Response(null, { status: 500 });

        handleError({ json: {}, response }, retry);
      });
    });
};

const useFetchCallback = (url: string, method: METHOD): UseFetchCallback => {
  const rollbar = useRollbar();
  const [pending, setPending] = useState(false);
  const toast = useToast();

  const callback = (
    body?: Record<string, unknown>,
    handleSuccess?: CallbackSuccessHandler,
    handleError?: CallbackErrorHandler
  ) => {
    setPending(true);

    const handleErrorWithFallback: CallbackErrorHandlerWithFallback = (
      { json, response },
      retry
    ) => {
      if (
        response.status === HttpStatus.FORBIDDEN &&
        json.errors?.[0].code === INVALID_AUTHENTICITY_TOKEN
      ) {
        setCsrfToken(response.headers.get("X-CSRF-Token"));
        if (retry) {
          retry();
        }
      } else {
        if (response.status >= HttpStatus.INTERNAL_SERVER_ERROR) {
          rollbar.error(
            `Something went wrong (${method} ${url} ${response.status})`
          );
        }

        if (handleError) {
          handleError({ json, response });
        } else {
          toast({
            description:
              ERROR_MESSAGES[response.status] ?? t("shared:status.error"),
            status: "error",
          });
        }
      }
    };

    fetchRequest(
      url,
      body,
      method,
      handleSuccess,
      handleErrorWithFallback
    ).finally(() => {
      setPending(false);
    });
  };

  return [callback, pending];
};

export default useFetchCallback;
