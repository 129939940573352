import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

/* eslint-disable @typescript-eslint/no-magic-numbers */

const xxl = defineStyle({
  color: "blackish",
  fontFamily: "bree",
  fontSize: [40, 40, 64, 64, 64, 64],
  fontWeight: "bold",
  lineHeight: ["35px", "35px", "53px", "53px", "53px", "53px"],
  marginBottom: "0.75em",
});

const xl = defineStyle({
  color: "blackish",
  fontFamily: "bree",
  fontSize: [26, 26, 40, 40, 40, 40],
  fontWeight: "bold",
  lineHeight: ["35px", "35px", "53px", "53px", "53px", "53px"],
  marginBottom: "0.75em",
});

const lg = defineStyle({
  color: "blackish",
  fontFamily: "bree",
  fontSize: [20, 20, 30, 30, 30, 30],
  fontWeight: "bold",
  lineHeight: ["26px", "26px", "40px", "40px", "40px", "40px"],
  marginBottom: "1em",
});

const md = defineStyle({
  color: "blackish",
  fontFamily: "inter",
  fontSize: [18, 18, 24, 24, 24, 24],
  fontWeight: "bold",
  lineHeight: ["25px", "25px", "32px", "32px", "32px", "32px"],
  marginBottom: "1em",
  "p + &, ul + &, ol + &": {
    marginTop: "2em",
  },
});

const sm = defineStyle({
  "& + div > table": {
    marginTop: "1em",
  },
  color: "blackish",
  fontFamily: "inter",
  fontSize: [17, 17, 21, 21, 21, 21],
  fontWeight: "bold",
  lineHeight: ["22px", "22px", "25px", "25px", "25px", "25px"],
});

const xs = defineStyle({
  "& + div > table": {
    marginTop: "1em",
  },
  color: "blackish",
  fontFamily: "inter",
  fontSize: [15, 15, 17, 17, 17, 17],
  fontWeight: "bold",
  lineHeight: ["22px", "22px", "25px", "25px", "25px", "25px"],
});

const Heading = defineStyleConfig({
  variants: { xs, sm, md, lg, xl, xxl },
});
/* eslint-enable @typescript-eslint/no-magic-numbers */

export default Heading;
