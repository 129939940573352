import { ErrorBoundary } from "@rollbar/react";
import { t } from "i18next";
import React, { PropsWithChildren } from "react";

import useStore from "../../hooks/useStore";
import Button from "../button";
import Container from "../layout/Container";
import MainHeader from "../main/MainHeader";

const ErrorDisplay = ({ resetError }: { resetError: () => void }) => {
  const store = useStore();
  const handleReset = () => {
    store.clear();
    resetError();
  };

  return (
    <Container>
      <MainHeader title={t("shared:status.error")}>
        <Button onClick={handleReset}>{t("shared:error.tryAgain")}</Button>
      </MainHeader>
    </Container>
  );
};

const Boundary = ({ children }: PropsWithChildren) => (
  <ErrorBoundary fallbackUI={ErrorDisplay}>{children}</ErrorBoundary>
);

export default Boundary;
