import { ResourceType, UserResource } from "../../types/serializers";
import { CURRENT_USER_ID } from "../lib/ids";
import { isLoadingStatus } from "../lib/status";

import useResourceSubscription from "./useResourceSubscription";
import useStore from "./useStore";

function useCurrentUser(): [user: UserResource | undefined, loading: boolean] {
  const id = CURRENT_USER_ID;
  const store = useStore();
  useResourceSubscription({ id, type: ResourceType.User });

  const user = store.getResource(ResourceType.User, id) as
    | UserResource
    | undefined;
  const status = store.getStatus(ResourceType.User, id);
  const isLoading = isLoadingStatus(status);

  return [user, isLoading];
}

export default useCurrentUser;
