import breeWoff from "@fontsource/bree-serif/files/bree-serif-latin-400-normal.woff";
import breeWoff2 from "@fontsource/bree-serif/files/bree-serif-latin-400-normal.woff2";
import interWoff from "@fontsource/inter/files/inter-latin-400-normal.woff";
import interWoff2 from "@fontsource/inter/files/inter-latin-400-normal.woff2";

const global = {
  "@font-face": [
    {
      fontDisplay: "swap",
      fontFamily: "Bree Serif",
      fontStyle: "normal",
      fontWeight: 400,
      src: `url(${breeWoff2}) format('woff2'), url(${breeWoff}) format('woff')`,
    },
    {
      fontDisplay: "swap",
      fontFamily: "inter",
      fontStyle: "normal",
      fontWeight: 400,
      src: `url(${interWoff}) format('woff2'), url(${interWoff2}) format('woff')`,
    },
  ],
  svg: {
    height: "auto",
    maxWidth: "100%",
  },
};

const styles = { global };

export default styles;
