import { EmptyResource, ResourceType } from "../../../types/serializers";
import { CollectionOpts, getCollectionId } from "../../lib/ids";

export enum Action {
  Edit = "edit",
  Index = "index",
  New = "new",
  Show = "show",
}

export enum Section {
  Main = "main",
  Admin = "admin",
}

export const sectionPrefix = {
  [Section.Main]: "",
  [Section.Admin]: "/admin",
};

const routeKeys: Partial<{ [key in ResourceType]: string }> = {
  [ResourceType.PillarPage]: "onderwerpen",
};

export const routeKey = (type: ResourceType) => routeKeys[type] ?? type;

class Store {
  public indexRoute<T extends ResourceType>(
    type: T,
    opts: CollectionOpts<T> | undefined = undefined,
    section: Section = GLOBALS.section
  ) {
    return this.resourceRoute(
      { id: getCollectionId(type, opts), type: ResourceType.Collection },
      section
    );
  }

  public resourceRoute(data: EmptyResource, section?: Section): string;
  public resourceRoute(
    data: Partial<EmptyResource> | undefined,
    section?: Section
  ): undefined;
  public resourceRoute(
    data: Partial<EmptyResource> | undefined,
    section: Section = GLOBALS.section
  ): string | undefined {
    const { id, type } = data ?? {};

    if (id === undefined || type === undefined) {
      return undefined;
    }

    if (type === ResourceType.Collection) {
      const resourceType = this.collectionResourceType(id);

      return this.buildRoute(
        section,
        id.replace(resourceType, routeKey(resourceType))
      );
    }

    return this.buildRoute(section, routeKey(type), id);
  }

  private buildRoute(section: Section, ...segments: string[]) {
    return [sectionPrefix[section], ...segments.filter(Boolean)].join("/");
  }

  private collectionResourceType(id: string) {
    const [type] = id.split("?");

    return type as ResourceType;
  }
}

export default Store;
