import { Flex, HStack, Icon, Text, UseToastOptions } from "@chakra-ui/react";
import React, { FC } from "react";
import {
  FiAlertCircle,
  FiAlertTriangle,
  FiCheck,
  FiXOctagon,
} from "react-icons/fi";

const ICONS: Record<string, FC> = {
  error: FiXOctagon,
  info: FiAlertCircle,
  success: FiCheck,
  warning: FiAlertTriangle,
};

const Toast = ({
  description,
  status,
}: UseToastOptions & { onClose: () => void }) => {
  return (
    <HStack
      bgColor={`status.${status}`}
      border="1px solid"
      borderColor={`pressed.${status}`}
      borderRadius="0.5em"
      marginBottom="-5em"
      marginTop="5em"
      padding="0.7em"
    >
      {status && <Icon as={ICONS[status]} />}
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        {description && <Text color="blackish">{description}</Text>}
      </Flex>
    </HStack>
  );
};

export default Toast;
