import Health from "./categoryIcons/health.svg";
import Law from "./categoryIcons/law.svg";
import Living from "./categoryIcons/living.svg";
import Money from "./categoryIcons/money.svg";
import Pension from "./categoryIcons/pension.svg";
import Relations from "./categoryIcons/relations.svg";
import Study from "./categoryIcons/study.svg";
import Tax from "./categoryIcons/tax.svg";
import Work from "./categoryIcons/work.svg";

const categories = [
  { icon: Tax, label: "Belasting" },
  { icon: Money, label: "Financiële onafhankelijkheid" },
  { icon: Money, label: "Geld" },
  { icon: Health, label: "Gezondheid" },
  { icon: Pension, label: "Pensioen" },
  { icon: Law, label: "Recht" },
  { icon: Relations, label: "Relaties" },
  { icon: Study, label: "Studie" },
  { icon: Work, label: "Werk" },
  { icon: Living, label: "Wonen" },
];

export default categories;
