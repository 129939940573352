import {
  CollectionFilters,
  CollectionObjectKeys,
  CollectionOrderDirection,
  CollectionOrderings,
  ResourceType,
} from "../../types/serializers";
import { Action } from "../components/store/Router";

export const NOT_NULL_FILTER = "$notNull";
export const NULL_FILTER = "$null";
export const CURRENT_USER_ID = "current";
export const SYSTEM_USER_ID = "-1";
export const AI_USER_ID = "-2";
export const GUEST_USER_ID = "-3";

export interface CollectionOpts<T extends ResourceType | unknown = unknown> {
  filter?: CollectionFilters<T>;
  order?: CollectionOrderings<T>;
  page?: number;
  pageSize?: number;
  query?: string;
}

const filterRegex = /filter\[(?<key>.*)\]\[]/;

export const collectionOptsFromId = <T extends ResourceType>(
  id: string
): CollectionOpts<T> =>
  collectionOptsFromQuery(new URLSearchParams(id.split("?")[1]));

export const collectionOptsFromQuery = <T extends ResourceType>(
  queryParams: URLSearchParams
): CollectionOpts<T> => {
  const page = queryParams.get("page");
  const pageSize = queryParams.get("page_size");

  return {
    filter: filtersFromQuery<T>(queryParams),
    order: orderingsFromQuery<T>(queryParams),
    page: page ? parseInt(page) : undefined,
    pageSize: pageSize ? parseInt(pageSize) : undefined,
    query: queryParams.get("query") ?? undefined,
  };
};

const filtersFromQuery = <T extends ResourceType>(
  queryParams: URLSearchParams
): CollectionFilters<T> =>
  [...queryParams.keys()].reduce<CollectionFilters<T>>((filters, filter) => {
    const match = filter.match(filterRegex);
    const value = queryParams.getAll(filter);
    if (match && value) {
      const key = match.groups!.key! as CollectionObjectKeys<T>;

      filters[key] = value;
    }

    return filters;
  }, {});

const orderingsFromQuery = <T extends ResourceType>(
  queryParams: URLSearchParams
): CollectionOrderings<T> =>
  queryParams
    .getAll("order[]")
    .reduce<CollectionOrderings<T>>((orderings, value) => {
      const [key, direction] = value.split(":");

      (orderings as Record<string, CollectionOrderDirection>)[key] =
        direction as CollectionOrderDirection;

      return orderings;
    }, {});

export const getCollectionId = <T extends ResourceType>(
  type: T,
  { filter, order, page, pageSize, query }: CollectionOpts<T> = {}
) => {
  const params = new URLSearchParams();

  if (filter) {
    Object.keys(filter)
      .sort()
      .forEach((key) => {
        const value = filter[key as CollectionObjectKeys<T>];

        if (Array.isArray(value)) {
          value.forEach((v) => {
            if (v) {
              params.append(`filter[${key}][]`, v);
            }
          });
        }
      });
  }

  if (order) {
    Object.keys(order).forEach((key) => {
      const value = (order as Record<string, CollectionOrderDirection>)[key];

      params.append("order[]", [key, value].join(":"));
    });
  }

  if (page && page > 1) {
    params.set("page", page.toString());
  }

  if (pageSize) {
    params.set("page_size", pageSize.toString());
  }

  if (query) {
    params.set("query", query);
  }

  const paramsString = new URLSearchParams(params).toString();

  if (Object.keys(paramsString).length === 0) {
    return type;
  }

  return `${type}?${paramsString}`;
};

export function formId(action: Action.New, resourceType: ResourceType): string;
export function formId(
  action: Action.Edit,
  resourceType: ResourceType,
  resourceId: string
): string;
export function formId(
  action: Action,
  resourceType: ResourceType,
  resourceId?: string
): string {
  switch (action) {
    case Action.Edit:
      return `${resourceType}/${resourceId}/edit`;
    case Action.New:
      return `${resourceType}/new`;
    default:
      throw new Error(`Action is not a form type: ${action}`);
  }
}
