const blackish = "#363636";

const gray = {
  800: "#363636",
  dark: "#595959",
  light: "#DDDDDD",
  lighter: "#F5F5F7",
  main: "#9E9E9D",
  subheader: "#7A7A7A",
};

const primary = {
  50: "#E8EBFC",
  100: "#C0C6F7",
  200: "#97A1F2",
  300: "#6E7CED",
  400: "#4558E7",
  500: "#181989",
  600: "#141572",
  700: "#0f105b",
  800: "#091047",
  900: "#060A2D",
  dark: "#091047",
  darkActive: "#181989",
};

const nude = {
  500: "#FAF0EA",
  600: "#e8dcd4",
  700: "#d5c4b9",
};

const tertiary = "#FEB62A";

const purple = {
  main: "#78447D",
};

const status = {
  error: "#EB8585",
  neutral: "#8DD4E3",
  success: "#82CF8A",
  warning: "#FFA666",
};

const pressed = {
  error: "#964D4D",
  neutral: "#2999B1",
  success: "#49814E",
  warning: "#CA6217",
};

const background = {
  accordion: "#E1ECFA",
  avatar: "#FFF4DD",
  banner: "#FFF9ED",
  card: "#F4F9FF",
  footer: "#F5F5F7",
  nude: "#FAF0EA",
  speechBubble: "#D3D3D380",
  stroke: "#B6B6B6",
  system: "#8DD4E3",
};

const colors = {
  background,
  blackish,
  gray,
  nude,
  pressed,
  primary,
  purple,
  status,
  tertiary,
};

export default colors;
