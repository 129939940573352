import {
  BlogAuthorResource,
  BlogResource,
  ExpertiseDomainResource,
  PillarPageResource,
  Resource,
  ResourceType,
  TestResourceResource,
} from "../../types/serializers";

const toString = (
  resource: Resource | undefined,
  fallback?: string
): string => {
  if (!resource) {
    return fallback ?? "";
  }

  switch (resource.type) {
    case ResourceType.Blog:
      return (resource as BlogResource).attributes.title;
    case ResourceType.BlogAuthor:
      return (resource as BlogAuthorResource).attributes.name;
    case ResourceType.ExpertiseDomain:
      return `${(resource as ExpertiseDomainResource).attributes.name} (${
        resource.id
      })`;
    case ResourceType.PillarPage:
      return (resource as PillarPageResource).attributes.title;
    case ResourceType.TestResource:
      return (resource as TestResourceResource).attributes.title;
    default:
      return fallback ?? `${resource.type} ${resource.id}`;
  }
};

export default toString;
