import { CollectionResource, ResourceType } from "../../types/serializers";
import { CollectionOpts, getCollectionId } from "../lib/ids";

import useResource from "./useResource";

const useCollection = <T extends ResourceType>(
  type?: T,
  opts?: CollectionOpts<T>
) =>
  useResource<ResourceType.Collection, CollectionResource<T>>(
    type
      ? {
          id: getCollectionId(type, opts),
          type: ResourceType.Collection,
        }
      : {}
  );

export default useCollection;
