const bree = "'bree serif', Helvetica, serif";
const inter = "'inter', sans-serif";

const fonts = {
  body: inter,
  bree,
  inter,
};

export default fonts;
