import { EmptyResource } from "../../types/serializers";
import { ResourceStatus } from "../components/store/Store";

import useResourceSubscription from "./useResourceSubscription";
import useStore from "./useStore";

const useStatus = (
  resource: Partial<EmptyResource> | undefined,
  opts?: {
    fetch?: boolean;
    root?: string;
  }
): ResourceStatus => {
  const { type, id } = resource ?? {};
  const store = useStore();

  useResourceSubscription(resource, opts);

  if (!type || !id) {
    return ResourceStatus.Missing;
  }

  return store.getStatus(type, id, opts?.root, opts?.fetch);
};

export default useStatus;
