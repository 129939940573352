import { useEffect, useState } from "react";

import { EmptyResource } from "../../types/serializers";

import useStore from "./useStore";

const useResourceSubscription = (
  resource: Partial<EmptyResource> | undefined,
  opts?: { root?: string }
) => {
  const store = useStore();
  const [, setInvalidate] = useState(0);

  const { type, id } = resource ?? {};

  useEffect(() => {
    if (type && id) {
      return store.subscribe(type, id, setInvalidate, opts?.root);
    }

    return () => null;
  }, [type, id]);
};

export default useResourceSubscription;
