/* eslint-disable @typescript-eslint/no-magic-numbers */
const textStyles = {
  body: {
    color: "blackish",
    fontFamily: "inter",
    fontSize: [16, 16, 17, 17, 17, 17],
    lineHeight: ["21px", "21px", "24px", "24px", "24px", "24px"],
  },
  bodyBlog: {
    color: "blackish",
    fontFamily: "inter",
    fontSize: [16, 16, 19, 19, 19, 19],
    lineHeight: ["28px", "28px", "34px", "34px", "34px", "34px"],
  },
  bodySmall: {
    fontFamily: "inter",
    fontSize: [14, 14, 15, 15, 15, 15],
    lineHeight: ["17px", "17px", "20px", "20px", "20px", "20px"],
  },
  caption: {
    color: "gray.main",
    fontFamily: "inter",
    fontSize: 14,
    lineHeight: ["14px", "14px", "20px", "20px", "20px", "20px"],
    marginTop: "0.2em",
  },
  light: {
    fontFamily: "inter",
    fontSize: [16, 16, 17, 17, 17, 17],
    fontWeight: 300,
    lineHeight: ["21px", "21px", "24px", "24px", "24px", "24px"],
  },
  menuItem: {
    fontFamily: "bree",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
  },
  menuItemMobile: {
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "32px",
  },
};
/* eslint-enable @typescript-eslint/no-magic-numbers */

export default textStyles;
