import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "../../locales";

i18n.use(initReactI18next).init({
  fallbackLng: "nl",
  interpolation: {
    // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    escapeValue: false,
  },
  lng: "nl",
  resources,
});
