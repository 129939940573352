import HttpStatus from "http-status-codes";
import React from "react";

import {
  DefinitionForType,
  Resource,
  ResourceType,
} from "../../../types/serializers";
import { headers } from "../../lib/api";

import Config, { ModelConfig } from "./Config";
import Router from "./Router";
import { JsonAPIData } from "./StoreContextProvider";

type Subscription = React.Dispatch<React.SetStateAction<number>>;

export enum ResourceStatus {
  Missing,
  Fetching,
  Present,
  NotFound,
  Forbidden,
  Error,
  MarkedForRefresh,
  Refreshing,
}

interface ResourceData<T extends ResourceType | unknown = unknown> {
  status: ResourceStatus | undefined;
  subscriptions: Subscription[];
  value: DefinitionForType<T> | undefined;
}

export interface StoreOpts {
  models?: Partial<Record<ResourceType, ModelConfig>>;
  resourceFetchTimer?: number;
  testMode?: boolean;
}

class Store {
  public router: Router;

  private config: Config;

  private resourceFetchTimer: number;

  private resources: {
    [root: string]: {
      [type: string]: {
        [id: string]: ResourceData;
      };
    };
  } = {};

  private testMode: boolean;

  private testQueue: Array<[url: string, callback: () => void]>;

  public constructor(
    seed: { [root: string]: JsonAPIData },
    { models, resourceFetchTimer, testMode }: StoreOpts = {}
  ) {
    this.config = new Config(models);
    this.resourceFetchTimer = resourceFetchTimer ?? 100;
    this.testMode = testMode ?? false;
    this.testQueue = [];
    this.router = new Router();

    Object.keys(seed).forEach((root) => {
      this.processData(seed[root], root);
    });
  }

  public clear() {
    Object.keys(this.resources).forEach((root) => {
      Object.keys(this.resources[root]).forEach((type) => {
        Object.keys(this.resources[root][type]).forEach((id) => {
          this.resources[root][type][id].status = ResourceStatus.Missing;
          this.resources[root][type][id].value = undefined;
          this.broadcastUpdate(type as ResourceType, id, root);
        });
      });
    });
  }

  public getOrFetch<T extends ResourceType>(
    type: T,
    id: string,
    root = GLOBALS.root
  ): Promise<DefinitionForType<T> | undefined> {
    if (
      [ResourceStatus.Present, ResourceStatus.Refreshing].includes(
        this.getStatus(type, id, root)
      )
    ) {
      const { value } = this.getResourceData(type, id, root);

      return Promise.resolve(value);
    }

    const resourceUrl = root + this.router.resourceRoute({ id, type });

    return this.fetchResourceRequest(type, id, root, resourceUrl).then(() => {
      const { value } = this.getResourceData(type, id, root);

      return Promise.resolve(value);
    });
  }

  public getResource(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ): Resource | undefined {
    const { value } = this.getResourceData(type, id, root);

    if (this.getStatus(type, id, root) === ResourceStatus.Missing) {
      this.fetchResourceAsync(type, id, root);
    }

    return value;
  }

  public getStatus(
    type: ResourceType,
    id: string,
    root = GLOBALS.root,
    fetch?: boolean
  ): ResourceStatus {
    const { status } = this.getResourceData(type, id, root);

    if (fetch && this.getStatus(type, id, root) === ResourceStatus.Missing) {
      this.fetchResourceAsync(type, id, root);
    }

    return status ?? ResourceStatus.Missing;
  }

  public processData(data: JsonAPIData, root = GLOBALS.root) {
    if (data.data) {
      this.setResourceValue(data.data.type, data.data.id, root, data.data);
    }

    data.included?.forEach((resource) => {
      this.setResourceValue(resource.type, resource.id, root, resource);
    });
  }

  public processTestQueue(url: string) {
    const [_url, callback] =
      this.testQueue.find(([queuedUrl]) => queuedUrl === url) ?? [];

    if (callback) {
      callback();
    }
  }

  public refreshResource(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ): void {
    if (this.shouldRefreshResource(type, id, root)) {
      this.setStatus(type, id, root, ResourceStatus.MarkedForRefresh, false);
      this.fetchResourceAsync(type, id, root);
    }
  }

  public subscribe(
    type: ResourceType,
    id: string,
    callback: Subscription,
    root = GLOBALS.root
  ): () => void {
    this.initializeResource(type, id, root);

    this.resources[root][type][id].subscriptions.push(callback);

    // return cleanup function
    return (): void => {
      const index =
        this.resources[root][type][id].subscriptions.indexOf(callback);

      if (index !== -1) {
        this.resources[root][type][id].subscriptions.splice(index, 1);
      }
    };
  }

  public testResourceQueue() {
    return this.testQueue.map(([url]) => url);
  }

  private broadcastUpdate(type: ResourceType, id: string, root = GLOBALS.root) {
    this.getSubscriptions(type, id, root).forEach((sub) => {
      sub((prev) => prev + 1);
    });
  }

  private fetchResource(type: ResourceType, id: string, root = GLOBALS.root) {
    if (!this.shouldFetch(type, id, root)) {
      return;
    }

    const refresh =
      this.getStatus(type, id, root) === ResourceStatus.MarkedForRefresh;

    const newStatus = refresh
      ? ResourceStatus.Refreshing
      : ResourceStatus.Fetching;

    this.setStatus(type, id, root, newStatus, !refresh);

    const resourceUrl = root + this.router.resourceRoute({ id, type });

    if (this.testMode) {
      this.testQueue.push([
        resourceUrl,
        () => this.fetchResourceRequest(type, id, root, resourceUrl),
      ]);

      return;
    }

    this.fetchResourceRequest(type, id, root, resourceUrl);
  }

  private fetchResourceAsync(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ) {
    if (typeof window === "undefined") {
      return;
    } else if (typeof window.requestIdleCallback !== "undefined") {
      window.requestIdleCallback(() => this.fetchResource(type, id, root), {
        timeout: this.resourceFetchTimer,
      });
    } else {
      window.setTimeout(
        () => this.fetchResource(type, id, root),
        this.resourceFetchTimer
      );
    }
  }

  private fetchResourceRequest(
    type: ResourceType,
    id: string,
    root = GLOBALS.root,
    resourceUrl: string
  ) {
    return fetch(resourceUrl, { headers: headers() }).then((response) => {
      if (response.status === HttpStatus.OK) {
        return response.json().then((json) => {
          this.processData(json, root);
        });
      } else if (response.status === HttpStatus.NOT_FOUND) {
        this.purgeResource(type, id, root);
        this.setStatus(type, id, root, ResourceStatus.NotFound);
      } else if (response.status === HttpStatus.FORBIDDEN) {
        this.purgeResource(type, id, root);
        this.setStatus(type, id, root, ResourceStatus.Forbidden);
      } else {
        this.purgeResource(type, id, root);
        this.setStatus(type, id, root, ResourceStatus.Error);
      }

      return Promise.resolve();
    });
  }

  private getResourceData<T extends ResourceType>(
    type: T,
    id: string,
    root = GLOBALS.root
  ): ResourceData<T> | Record<string, never> {
    return (this.resources[root]?.[type]?.[id] as ResourceData<T>) ?? {};
  }

  private getSubscriptions(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ): Subscription[] {
    const { subscriptions } = this.getResourceData(type, id, root);

    return subscriptions ?? [];
  }

  private initializeResource(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ) {
    if (!this.resources[root]) {
      this.resources[root] = {};
    }
    if (!this.resources[root][type]) {
      this.resources[root][type] = {};
    }
    if (!this.resources[root][type][id]) {
      this.resources[root][type][id] = {
        status: ResourceStatus.Missing,
        subscriptions: [],
        value: undefined,
      };
    }
  }

  private processResourceValue<T extends ResourceType>(
    resource: DefinitionForType<T>
  ) {
    return {
      ...resource,
      attributes: { ...(resource.attributes ?? {}) },
      relationships: { ...(resource.relationships ?? {}) },
    };
  }

  private purgeResource(type: ResourceType, id: string, root = GLOBALS.root) {
    this.setResource(type, id, root, {
      value: undefined,
    });
  }

  private setResource<T extends ResourceType>(
    type: T,
    id: string,
    root = GLOBALS.root,
    partial: Partial<ResourceData<T>>
  ) {
    this.initializeResource(type, id, root);

    this.resources[root][type][id] = {
      ...this.resources[root][type][id],
      ...partial,
    };
  }

  private setResourceValue<T extends ResourceType>(
    type: T,
    id: string,
    root: string,
    newValue: Resource<T>
  ) {
    this.setResource(type, id, root, {
      value: this.processResourceValue(
        newValue as unknown as DefinitionForType<T>
      ),
    });

    this.setStatus(type, id, root, ResourceStatus.Present);
  }

  private setStatus(
    type: ResourceType,
    id: string,
    root = GLOBALS.root,
    status: ResourceStatus,
    broadcast = true
  ) {
    this.setResource(type, id, root, { status });

    if (broadcast) {
      this.broadcastUpdate(type, id, root);
    }
  }

  private shouldFetch(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ): boolean {
    return [ResourceStatus.Missing, ResourceStatus.MarkedForRefresh].includes(
      this.getStatus(type, id, root)
    );
  }

  private shouldRefreshResource(
    type: ResourceType,
    id: string,
    root = GLOBALS.root
  ): boolean {
    return (
      this.config.modelIsFetchable(type) &&
      ![ResourceStatus.Missing, ResourceStatus.MarkedForRefresh].includes(
        this.getStatus(type, id, root)
      )
    );
  }
}

export default Store;
