import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const navbar = defineStyle({
  "&:hover, &:visited": {
    color: "white",
  },
  color: "white",
});

const sidebar = defineStyle({
  "&:hover, &:visited": {
    color: "black",
  },
  color: "black",
});

const inlineText = defineStyle({
  textDecoration: "underline",
});

const button = defineStyle((props) => ({
  ...props.theme.components.Button.baseStyle,
  ...props.theme.components.Button.variants.solid(props),
  ...props.theme.components.Button.sizes.md,
  "&:hover, &:visited": {
    color: "white",
    textDecoration: "unset",
  },
  alignItems: "center",
  display: "inline-flex",
}));

const outlinedButton = defineStyle((props) => ({
  ...props.theme.components.Button.baseStyle,
  ...props.theme.components.Button.variants.outline(props),
  ...props.theme.components.Button.sizes.md,
  "&:hover": {
    textDecoration: "unset",
  },
  "&:visited": {
    color: props.colorScheme ?? "primary",
  },
  alignItems: "center",
  color: "primary.500",
  display: "inline-flex",
}));

const paginationButton = defineStyle((props) => ({
  ...props.theme.components.Button.baseStyle,
  ...props.theme.components.Button.variants.ghost(props),
  ...props.theme.components.Button.sizes.sm,
  "&[aria-current]": {
    background: "primary.100",
  },
  "&[aria-disabled]": {
    boxShadow: "none",
    cursor: "not-allowed",
    opacity: "0.4",
  },
  alignItems: "center",
  color: "primary.600",
  display: "inline-flex",
}));

const Link = defineStyleConfig({
  baseStyle: {
    "&:hover": {
      color: "primary.dark",
      textDecoration: "underline",
    },
    "&:visited": {
      color: "purple.main",
    },
    color: "primary.darkActive",
    fontSize: "inherit",
    textDecoration: "none",
  },
  variants: {
    button,
    inlineText,
    navbar,
    outlinedButton,
    paginationButton,
    sidebar,
  },
});

export default Link;
