import { t } from "i18next";

import { JsonAPIData } from "../components/store/StoreContextProvider";

export const setCsrfToken = (token: string | null) => {
  if (token) {
    (global as any).GLOBALS.csrfToken = token;
  }
};

export const setCsrfTokenFromDocument = () => {
  setCsrfToken(
    (typeof document === "undefined" ? undefined : document)?.head
      ?.querySelector("meta[name='csrf-token']")
      ?.getAttribute("content") ?? "missing"
  );
};

export const headers = ({
  accept,
  contentType,
}: {
  accept?: string;
  contentType?: string;
} = {}) => ({
  Accept: accept ?? "application/vnd.api+json",
  "Content-Type": contentType ?? "application/vnd.api+json",
  "X-CSRF-Token": GLOBALS.csrfToken,
});

export const validateStatus = (resp: Response) => {
  if (!resp.ok) {
    throw new Error(`Invalid status: ${resp.status}`);
  }

  return resp;
};

export const parseJson = (resp: Response) => resp.json();

export const errorMessageFromResponse = (json: JsonAPIData) => {
  if (!json.errors) {
    return t("shared:status.error");
  }

  return json.errors?.map((error) => error.title).join("\n");
};
