import {
  LinkOverlayProps,
  chakra,
  forwardRef,
  useStyleConfig,
} from "@chakra-ui/react";
import React from "react";

// Overwrite from chakra so we can reuse the Link styles
const LinkOverlay = forwardRef<LinkOverlayProps, "a">(function LinkOverlay(
  props,
  ref
) {
  const { isExternal, target, rel, className, ...rest } = props;
  const styles = useStyleConfig("Link", props);

  return (
    <chakra.a
      {...rest}
      __css={{
        ...styles,
        "&::before": {
          content: "''",
          cursor: "inherit",
          display: "block",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 0,
        },
        position: "static",
      }}
      className="chakra-linkbox__overlay"
      ref={ref}
      rel={isExternal ? "noopener noreferrer" : rel}
      target={isExternal ? "_blank" : target}
    />
  );
});

export default LinkOverlay;
