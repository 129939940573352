import { EmptyResource } from "../../../../types/serializers";
import useStore from "../../../hooks/useStore";
import { Section } from "../Router";

const useResourceRoute = (
  data: Partial<EmptyResource> | undefined,
  section: Section = GLOBALS.section
): string | undefined => {
  const store = useStore();

  return store.router.resourceRoute(data, section);
};

export default useResourceRoute;
